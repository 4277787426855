import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuestion, IRatingAnsweredOption } from '../../store/business/interfaces';
import { LoadingSpinnerButton } from '../atoms/LoadingSpinnerButton';
import styles from './VotingForm.module.scss';
import ratingStyles from './RatingForm.module.scss';
import { RatingAnswer } from '../atoms/RatingAnswer';
import { useEffect } from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import { BallotCounter } from '../atoms/BallotCounter';
import { CSSTransition } from 'react-transition-group';
import { MessageType, showMessage } from '../../shared/notifications';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';

export interface RatingFormProps {
    question: IQuestion;
    onSend: (answeredOptions: IRatingAnsweredOption[][]) => void;
}
/**
 * handles User Input during Vote
 * @param props :  { question: IQuestion; onSend: (answeredOptions: IRatingAnsweredOption[]) => void; }
 * @returns RatingAnswer for each existing AnswerOption in Array; Button to send voted answers, BallotCounter if voteSplitting = true && voteWeight > 1
 */
export const RatingForm = (props: RatingFormProps) => {
    const { t } = useTranslation();

    const [answeredOptions, setAnsweredOptions] = useState<IRatingAnsweredOption[][]>([]);
    const [currentBallot, setCurrentBallot] = useState<number>(0);
    const [, setCurrentQuestion] = useState<IQuestion>();

    const isLoading = useSelector((state: AppState) => state.system.isLoading);

    const hasUnvotedRatingOptions = answeredOptions[currentBallot] && answeredOptions[currentBallot].some((ao) => ao.rating === undefined)

    const prevBallotContainerRef = React.useRef(null);
    const nextBallotContainerRef = React.useRef(null);

    useEffect(() => {
        const ballots: IRatingAnsweredOption[][] = [];
        const ballotCount = props.question.allowVotesSplitting ? props.question.userVoteWeight : 1;
        for (let i = 0; i < ballotCount; i++) {
            if (props.question.answeredOptions.length > 0) {
                const ballotAnswerOptions = (props.question.answeredOptions as IRatingAnsweredOption[]).filter(
                    (ao: IRatingAnsweredOption) => ao.ballotNumber === i + 1
                );
                ballots[i] = ballotAnswerOptions;
            } else {
                const ballot = props.question.answerOptions.map((ao) => {
                    return {
                        id: ao.id,
                        voteWeight: props.question.allowVotesSplitting ? 1 : props.question.userVoteWeight,
                        ballotNumber: i + 1
                    };
                });
                ballots[i] = ballot;
            }
        }
        setCurrentQuestion((oldQuestion) => {
            if (oldQuestion?.id !== props.question?.id) {
                setCurrentBallot(0);
                return props.question;
            }
            return oldQuestion
        });
        setAnsweredOptions(ballots);

    }, [props.question]);


    const onSelectAnswer = (answerId: string, amount: number) => {
        const answers = [...answeredOptions];
        const newAnswer: IRatingAnsweredOption = { ...answers[currentBallot].find(a => a.id === answerId), rating: amount }
        answers[currentBallot] = answers[currentBallot]
            .filter((ao) => ao.id !== answerId)
            .concat(newAnswer);
        setAnsweredOptions(answers);
    };

    const usePreviousSelection = () => {
        const previousIndex = currentBallot - 1;
        if (previousIndex === -1) return;

        const updatedAnswerOptions = [...answeredOptions];
        const currentAnswerOptions = updatedAnswerOptions[currentBallot];

        updatedAnswerOptions[currentBallot] = updatedAnswerOptions[previousIndex].map((uao) => {
            const cao = currentAnswerOptions.find((cao) => cao.id === uao.id);
            return { ...cao, rating: uao.rating };
        })
        setAnsweredOptions(updatedAnswerOptions);

    }

    return (
        <>
            {
                props.question.allowVotesSplitting && props.question.userVoteWeight > 1 && (
                    <BallotCounter
                        question={props.question}
                        setCurrent={setCurrentBallot}
                        currentBallot={currentBallot}
                        disabled={answeredOptions[currentBallot] &&
                            answeredOptions[currentBallot].some((ao) => ao.rating === undefined)}
                        disabledMessage={t('messages.ratingVoteAllRatings')}
                    />
                )
            }

            {props.question.userVoteWeight > 1 && props.question.allowVotesSplitting && (
                <CSSTransition
                    nodeRef={prevBallotContainerRef}
                    in={currentBallot > 0}
                    timeout={150}
                    unmountOnExit
                    data-testid='css-transition'
                    classNames={{
                        enter: ratingStyles.ballotContainerEnter,
                        enterActive: ratingStyles.ballotContainerEnterActive,
                        enterDone: ratingStyles.ballotContainerEnterDone,
                        exit: ratingStyles.ballotContainerExit,
                        exitActive: ratingStyles.ballotContainerExitActive,
                        exitDone: ratingStyles.ballotContainerExitDone,
                    }}
                >
                    <div ref={prevBallotContainerRef} className={ratingStyles.nextBallotContainer}>
                        <button onClick={usePreviousSelection} disabled={props.question.hasAnswered}>
                            {t('form.reUsePreviousSelection')}
                        </button>
                    </div>
                </CSSTransition>
            )}

            {
                props.question.answerOptions.map((ao) => {
                    return <RatingAnswer
                        key={`ao_${ao.id}`}
                        maxRating={props.question.maxRating}
                        disabled={props.question.hasAnswered}
                        questionText={ao.title}
                        updateAnswers={onSelectAnswer}
                        answeredOption={
                            answeredOptions[currentBallot] && answeredOptions[currentBallot].find((o) => o.id === ao.id)
                        }
                        currentBallot={currentBallot}
                        answerId={ao.id}
                    />
                })
            }


            <div
                className={ratingStyles.ratingFormCalloutContainer}
                data-testid='ratingFormCalloutContainer'
            >
                <FontIcon iconName={'Info'} />
                <span>{t(`form.ratingVoteCallout`)}</span>
            </div>

            {props.question.userVoteWeight > 1 && props.question.allowVotesSplitting && (
                <CSSTransition
                    nodeRef={nextBallotContainerRef}
                    in={currentBallot < answeredOptions.length - 1}
                    timeout={150}
                    unmountOnExit
                    classNames={{
                        enter: ratingStyles.ballotContainerEnter,
                        enterActive: ratingStyles.ballotContainerEnterActive,
                        enterDone: ratingStyles.ballotContainerEnterDone,
                        exit: ratingStyles.ballotContainerExit,
                        exitActive: ratingStyles.ballotContainerExitActive,
                        exitDone: ratingStyles.ballotContainerExitDone,
                    }}
                >
                    <div ref={nextBallotContainerRef} className={ratingStyles.nextBallotContainer}>
                        <button
                            className={hasUnvotedRatingOptions && ratingStyles.disabled}
                            onClick={() => {
                                if(hasUnvotedRatingOptions){
									showMessage('messages.ratingVoteAllRatings', MessageType.WARN);
                                }
                                else {
                                    currentBallot < props.question.userVoteWeight - 1 && setCurrentBallot(currentBallot + 1)
                                }
                            }}
                        >
                            {t('form.nextBallot')}
                        </button>
                    </div>
                </CSSTransition>
            )}

            <div
                onClick={() => {
                    if (answeredOptions.some((ao) => ao.some((a) => a.rating === undefined))) {
                        showMessage('messages.fillOutAllBallots', MessageType.WARN);
                    }
                }}
            >
                <LoadingSpinnerButton
                    className={styles.button}
                    loadingClassName={styles.loadingButton}
                    text={t('form.vote')}
                    onClick={() => (answeredOptions) && props.onSend(answeredOptions)}
                    disabled={answeredOptions.some((ao) => ao.some((a) => a.rating === undefined)) || props.question.hasAnswered || isLoading.includes('answer')}
                />
            </div>
        </>
    )

}