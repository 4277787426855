import React from 'react';

import styles from './HintTexts.module.scss';

import { useTranslation } from 'react-i18next';
import { IQuestion, ISurvey } from '../../store/business/interfaces';
import { formatFloat } from '../../shared/utils';

interface Props {
	question: IQuestion;
	survey: ISurvey;
}
export const HintTexts = (props: Props) => {
	const { t } = useTranslation();

	return (
		<>
				<div className={styles.hintTexts}>
					{props.question.security === 'private' && (
						<span className={styles.hintText}>{t('form.secretQuestion')}</span>
					)}
					{props.question.kind !== 'text' && !props.question.allowVotesSplitting && props.question.userVoteWeight !== 1 && (
						<span className={styles.hintText}>{t('form.userVoteWeight', {
							voteWeight: formatFloat(props.question.userVoteWeight),
							unit: formatFloat(props.survey.useVoteWeightInPercent ? t('form.voteWeightUnit.percent') : t('form.voteWeightUnit.votes'))
						})}
						</span>
					)}
			</div>
		</>
	);
};
