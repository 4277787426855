import React, { useContext, useEffect, useState } from 'react';
import styles from './Voting.module.scss';
import { IQuestion } from '../store/business/interfaces';
import { SignalRContext, ISignalRConnection } from '../shared/SignalRContext';
import { QuestionService } from '../services/QuestionService';
import { useSelector } from 'react-redux';
import store, { AppState } from '../store/store';
import { VotingForm } from '../components/organisms/VotingForm';
import { useTabIsVisible } from '../hooks';
import { anyInArray } from '../shared/utils';
import { VotingService } from '../services/VotingService';
import { showMessage, clearMessages, MessageType } from '../shared/notifications';
import { useTranslation } from 'react-i18next';
import { ResultGraph } from '../components/molecules/ResultGraph';
import { MultipleQuestionsToolbar } from '../components/molecules/MultipleQuestionsToolbar';
import { ResultPersonGraph } from '../shared/components/molecules/ResultPersonGraph';
import { VotingHeader } from '../components/molecules/VotingHeader';
import { ResultGraphRating } from '../components/molecules/ResultGraphRating';
import { useHistory } from 'react-router-dom';
import { addError } from '../store/system/system-slice';
import { ResultPieChart } from '../components/molecules/ResultPieChart';

/**
 * manages SignalR connection (login status);
 * loads all currently active Questions;
 * calls QuestionService.reloadAllQuestions();
 * @param props :  {survey: ISurvey;}
 * @returns MultipleQuestionsToolbar, <VotingHeader question={selectedQuestion || questionResult} />, VotingForm of selected Question or results of Question after vote is finished
 */
export const Voting = () => {
	const signalRConn = useContext<ISignalRConnection>(SignalRContext);
	const { t } = useTranslation();
	const history = useHistory();

	const survey = useSelector((state: AppState) => state.business.survey);
	const questions = useSelector((state: AppState) => state.business.questions);
	const questionResult = useSelector((state: AppState) => state.business.questionResult);
	const connectionId = useSelector((state: AppState) => state.system.connectionId);
	const appConfig = useSelector((state: AppState) => state.session.appConfig);
	const pieHeight = useSelector((state: AppState) => state.system.pieHeight);
	const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

	const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>(questions ? questions[0] : undefined);

	useEffect(() => {
		function handleResize() {
			setIsSmallScreen(window.innerWidth < 771);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if(connectionId) {
			signalRConn.on('member', (args) => {
				if (args.type === 'survey') {
					if (args.action === 'delete' || args.action === 'archive') {
						store.dispatch(addError({area: 'forcedLogout', message: t('messages.surveyErased')}));
						VotingService.logout().then(() => {history.push('/');});
					} else {
						VotingService.ensureSurvey(true);
					}
				} else if (args.type === 'member') {
					if (args.action === 'delete') {
						store.dispatch(addError({area: 'forcedLogout', message: t('messages.autoLogout')}));
						VotingService.logout().then(() => {history.push('/');});
					}
				} else if (args.type === 'connection') {
					if (args.action === 'login') {
						const loginConnectionId = args.connectionId;
						if (loginConnectionId !== connectionId) {
							store.dispatch(addError({area: 'forcedLogout', message: t('messages.singleLogout')}));
							VotingService.logout(true).then(() => {history.push('/');});
						}
					}
				} else if (args.type === 'question') {
					QuestionService.reloadAllQuestions(true);
				}
			});
			signalRConn.onReconnected(() => {
				QuestionService.reloadAllQuestions(true);
			});
			return () => {
				signalRConn.off('member');
			};	
		}
	}, [signalRConn, connectionId, history, t]);

	useEffect(() => {
		clearMessages();
		if (selectedQuestion?.hasAnswered) {
			showMessage('messages.answerSuccess', MessageType.SUCCESS, true);
		}
	}, [selectedQuestion]);

	useEffect(() => {
		let currentQuestion = questions?.find((q: any) => q.id === selectedQuestion?.id);
		if (!currentQuestion) currentQuestion = questions ? questions[0] : undefined;
		setSelectedQuestion(currentQuestion);
	}, [questions, selectedQuestion?.id]);

	useTabIsVisible((visible) => {
		if (visible) {
			QuestionService.reloadAllQuestions(true);
		}
	});

	useEffect(() => {
		if(appConfig.functionsKey) {
			QuestionService.reloadAllQuestions(false);
		}
	}, [appConfig.functionsKey]);
	
	return (
		<div className={styles.votingContainer}>
			<div className={styles.votingContentContainer}>
				<MultipleQuestionsToolbar
					questions={questions}
					selectedQuestion={selectedQuestion}
					onSelectionChanged={(newSelectedQuestion) => {
						setSelectedQuestion(questions?.find((q: any) => q.id === newSelectedQuestion.id));
					}}
				/>
				<VotingHeader
					question={selectedQuestion || (!survey.disableResultsInMemberView ? questionResult : null)}
				/>
				{selectedQuestion && (
					<>
						<VotingForm
							survey={survey}
							question={selectedQuestion}
							onSend={(answerOptions, comment) => {
								if (
									selectedQuestion.kind === 'choice' &&
									!anyInArray(answerOptions) &&
									selectedQuestion.votesPerMember === 1
								) {
									showMessage('messages.chooseOneAnswer', MessageType.WARN);
								} else if (
									selectedQuestion.kind === 'choice' &&
									!anyInArray(answerOptions) &&
									selectedQuestion.votesPerMember > 1
								) {
									showMessage('messages.chooseOneAnswerMin', MessageType.WARN);
								} else if (selectedQuestion.kind === 'text' && !comment) {
									showMessage('messages.enterComment', MessageType.WARN);
								} else {
									QuestionService.answerQuestion(selectedQuestion, answerOptions, comment);
								}
							}}
							onSendBallotAnswers={(answeredOptions) => {
								QuestionService.answerBallotQuestion(selectedQuestion, answeredOptions);
							}}
							onUnVote={() => {
								QuestionService.unAnswer(selectedQuestion);
							}}
							marginContainerName={styles.marginContainer}
						/>
					</>
				)}
				{questionResult && !survey.disableResultsInMemberView && (
					<>
						<div className={styles.votingResultContainer}  style={survey.showResultsAsPie ? isSmallScreen ? {height: pieHeight + 400} : {height: pieHeight + 70}: {height:""}}>
							<div className={styles.marginContainerInner}>
								<div className={styles.resultHeader}>{t('results.header')}</div>
								{questionResult.kind === 'choice' &&
									(!survey.showResultsAsPie ? (
										<ResultGraph question={questionResult} />
									) : (
										<ResultPieChart question={questionResult} />
									))
								}
								{questionResult.kind === 'text' && (
									<div className={styles.textResultContainer}>
										{questionResult.comments.sort().map((comment: string) => {
											return (
												<div className={styles.textResultOuter}>
													<div className={styles.textResult}>
														<div className={styles.textResultInner}>{comment}</div>
													</div>
												</div>
											);
										})}
									</div>
								)}
								{questionResult.kind === 'person' && (
									<ResultPersonGraph
										answerOptions={questionResult.answerOptions}
										useVoteWeightInPercent={survey.useVoteWeightInPercent}
										sortResultsByVotes={survey.sortResultsByVotes}
									></ResultPersonGraph>
								)}
								{questionResult.kind === 'rating' && (
									<ResultGraphRating questionResult={questionResult} question={selectedQuestion} />
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
