import React, { useEffect, useState } from 'react';
import { TextField } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import styles from './VotingForm.module.scss';
import { IAnsweredOption, IQuestion, ISurvey } from '../../store/business/interfaces';
import { LoadingSpinnerButton } from '../atoms/LoadingSpinnerButton';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';

interface Props {
	question: IQuestion;
	onSend: (answeredOptions: IAnsweredOption[], comments: string) => void;
	survey: ISurvey;
}

export const TextForm = (props: Props) => {
	const isLoading = useSelector((state: AppState) => state.system.isLoading);

	const [comment, setComment] = useState<string>(props.question.comment);
	const [answeredOptions, setAnsweredOptions] = useState<IAnsweredOption[]>(props.question.answeredOptions);

	const { t } = useTranslation();

	useEffect(() => {
		setAnsweredOptions(() => {
			return props.question.answeredOptions.map((ao) => ({ ...ao }));
		});
		setComment(props.question.comment);
	}, [props.question]);

	const upsertComment = (value: string) => {
		setComment(value);
	};

	const maxVotes = props.question.votesPerMember * props.question.userVoteWeight;
	const remainingVotes =
		maxVotes - answeredOptions.map((ao) => ao.voteWeight).reduce((sum, current) => sum + current, 0);


	return (

		<>
			<div>
				{(props.question.allowVotesSplitting && props.question.userVoteWeight > 1) ||
				(props.question.votesPerMember > 1 && props.question.answerOptions.length >= props.question.votesPerMember) ? (
					<span
						dangerouslySetInnerHTML={{
							__html: t(`form.remainingVoices`, {
								remainingVotes: `${
									props.survey.useVoteWeightInPercent
										? props.question.votesPerMember - answeredOptions.length
										: remainingVotes
								}`,
								maxVotes: `${props.survey.useVoteWeightInPercent ? props.question.votesPerMember : maxVotes}`,
							}),
						}}
					></span>
				) : (
					''
				)}
			</div>
			<TextField
				key={`${props.question.id}-comment`}
				disabled={props.question.hasAnswered}
				placeholder={t('form.textAnswer')}
				onChange={(e, newValue) => {
					upsertComment(newValue);
				}}
				multiline={true}
				rows={4}
				className={styles.commentField}
				resizable={false}
				maxLength={5000}
				defaultValue={props.question.comment}
			/>
			<LoadingSpinnerButton
				className={styles.button}
				loadingClassName={styles.loadingButton}
				text={t('form.vote')}
				onClick={() => answeredOptions && props.onSend(answeredOptions, comment)}
				disabled={!answeredOptions || props.question.hasAnswered || isLoading.includes('answer')}
			/>
		</>
	);
};
