import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PERSIST, persistReducer, persistStore } from 'redux-persist';

import { businessReducer } from './business/business-slice';
import { systemReducer } from './system/system-slice';
import { sessionReducer } from './session/session-slice';

const persistConfig = {
	key: 'votr-persisted',
	storage,
};

const sessionPersistReducer = persistReducer(persistConfig, sessionReducer);

//export const store : any = createStore(rootReducer, storeEnhancers(applyMiddleware(emptyMiddleware(), thunk)));

export const store = configureStore({
	reducer: {
		business: businessReducer,
		system: systemReducer,
		session: sessionPersistReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			//Date types are class instances and therefore are not recommended by reduxjs toolkit to be used in the state.
			//Since they are not persisted in our case we decided to exclude them from the serializability check.
			//Action types like persist can also be excluded.
			//https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
			serializableCheck: {
				// Ignore these action types
				ignoredActions: [PERSIST],
				// Ignore these field paths in all actions
				ignoredActionPaths: ['payload.created'],
				// Ignore these paths in the state
				ignoredPaths: ['system.messages'],
			},
		}),
	devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppState = ReturnType<typeof store.getState>;
export default store;
