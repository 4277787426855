import styles from '../../routes/Voting.module.scss'; // TODO: use own module.scss
import React from 'react';
import { IQuestion, IQuestionResult } from '../../store/business/interfaces';
import { SecurityIcon } from '../../shared/components/atoms/SecurityIcon';
import { QuestionKindIcon } from '../../shared/components/atoms/QuestionKindIcon';
import { QuestionConfigurationIcons } from '../../shared/components/atoms/QuestionConfigurationIcons';
import { VotesSplittingIcon } from '../../shared/components/atoms/VotesSplittingIcon';
import { Text } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../shared/components/atoms/Badge';
import { formatDate } from '../../shared/utils';

export interface Props {
	question: IQuestion | IQuestionResult;
}

export const VotingHeader = (props: Props) => {
	const { t } = useTranslation();

	return (
		<div className={styles.votingHeader}>
			<div className={styles.marginContainer}>
				<div className={styles.marginContainerInner}>
					{!props.question && <div className={styles.votingContentContainerNoVote}>{t('voting.noVoteActive')}</div>}
					{props.question && (
						<>
							<div className={styles.flexContainer}>
								<QuestionKindIcon question={props.question} />
								<SecurityIcon question={props.question} />
								<QuestionConfigurationIcons
									question={props.question}
									numberOfOptions={props.question.answerOptions.length}
								/>
								<VotesSplittingIcon question={props.question} />
							</div>
							<Text className={styles.votingQuestion}>{props.question.title}</Text>
							{props.question.description && (
								<div
									className={styles.votingDescription}
									dangerouslySetInnerHTML={{ __html: props.question.description }}
								></div>
							)}
							{props.question.isTimerTriggered && (
								<div className={styles.badge}>
									<Badge
										icon='Calendar'
										information={
											formatDate(new Date(props.question.startTime)) !== formatDate(new Date(props.question.stopTime))
												? `${formatDate(new Date(props.question.startTime))} - ${formatDate(
														new Date(props.question.stopTime)
												  )}`
												: formatDate(new Date(props.question.startTime))
										}
									></Badge>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
