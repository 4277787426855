import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAnsweredOption, IQuestion, ISurvey } from '../../store/business/interfaces';
import { CheckboxWithCounter } from '../atoms/CheckboxWithCounter';
import { LoadingSpinnerButton } from '../atoms/LoadingSpinnerButton';
import styles from './VotingForm.module.scss';
import round from 'lodash/round';
import { formatFloat } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';

interface Props {
	question: IQuestion;
	onSend: (answeredOptions: IAnsweredOption[]) => void;
	survey: ISurvey;
}

export const ChoiceForm = (props: Props) => {
	const { t } = useTranslation();
	const isLoading = useSelector((state: AppState) => state.system.isLoading);

	const [answeredOptions, setAnsweredOptions] = useState<IAnsweredOption[]>(props.question.answeredOptions);

	const maxVotes = round(props.question.votesPerMember * props.question.userVoteWeight, 3);

	const remainingVotes = 
		round(maxVotes - answeredOptions.map((ao: IAnsweredOption) => ao.voteWeight).reduce((sum, current) => sum + current, 0), 3);

	const onSelectAnswer = (answerId: string, amount: number) => {
		const answers = answeredOptions.filter((ao) => ao.id !== answerId);
		if (amount > 0) {
			answers.push({ id: props.question.answerOptions.find((o) => o.id === answerId)?.id, voteWeight: amount });
		}
		setAnsweredOptions(answers);
	};

	useEffect(() => {
		setAnsweredOptions(() => {
			return props.question.answeredOptions.map((ao) => ({ ...ao }));
		});
	}, [props.question]);

	return (
		<>
			{((!props.survey.useVoteWeightWithDecimals) && ((props.question.userVoteWeight > 1) ||
			(props.question.votesPerMember > 1 && props.question.answerOptions.length >= props.question.votesPerMember))) ? (
				<div style={{padding:"0 16px 20px 16px"}}>
					<span
						dangerouslySetInnerHTML={{
							__html: t(`form.remainingVoices`, {
								remainingVotes: `${formatFloat(
									props.survey.useVoteWeightInPercent
										? props.question.votesPerMember - answeredOptions.length
										: remainingVotes
								)}`,
								maxVotes: `${formatFloat(props.survey.useVoteWeightInPercent ? props.question.votesPerMember : maxVotes)}`,
							}),
						}}
					></span>
				</div>
			) : (
				''
			)}
			{props.question.answerOptions.map((o) => (
				<CheckboxWithCounter
					disabled={props.question.hasAnswered}
					key={`${o.id}${!!answeredOptions.find((ao) => ao.id === o.id)?.voteWeight}`}
					option={o}
					answer={answeredOptions.find((ao) => ao.id === o.id)}
					userVoteWeight={props.question.userVoteWeight}
					initialVoteWeight={props.question.allowVotesSplitting ? 1 : props.question.userVoteWeight}
					remainingVotes={remainingVotes}
					remainingAnswers={
						// unlimited
						Number.MAX_SAFE_INTEGER
						// limit to answerOptions
						// props.question.votesPerMember > 1 ? props.question.votesPerMember - answeredOptions.length : 1
					}
					showCounter={props.question.allowVotesSplitting && props.question.userVoteWeight > 1}
					onChange={(answerId, amount) => {
						onSelectAnswer(answerId, amount);
					}}
				/>
			))}
			<LoadingSpinnerButton
				className={styles.button}
				loadingClassName={styles.loadingButton}
				text={t('form.vote')}
				onClick={() => answeredOptions && props.onSend(answeredOptions)}
				disabled={!answeredOptions || props.question.hasAnswered || isLoading.includes('answer')}
			/>
		</>
	);
};
