import { FontIcon } from "office-ui-fabric-react";
import styles from '../../routes/Hub.module.scss';
import React, { useEffect, useRef} from "react";
import { Voting } from "../../routes/Voting";
import { useSelector } from "react-redux";
import store, { AppState } from "../../store/store";
import { VotingService } from "../../services/VotingService";
import { setIsMenuPanelOpen } from "../../store/system/system-slice";
import { QuestionService } from "../../doc";
import { useHistory } from "react-router-dom";
import { SignalR } from "../../shared/SignalRContext";

export interface IVotingPageProps {
}

export const VotingPage = (props:IVotingPageProps) => {
    const history = useHistory();

	const apiBasePath = useSelector((state: AppState) => state.system.apiBasePath);
	const connectionId = useSelector((state: AppState) => state.system.connectionId);
	const user = useSelector((state: AppState) => state.session.user);
	const survey = useSelector((state: AppState) => state.business.survey);
	const transitionNodeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
		if(!user) {
			history.push('/');
		}
	}, [user, history])	


    useEffect(()=>{
        if(connectionId) {
            VotingService.ensureSurvey().then(()=>{
                QuestionService.reloadAllQuestions();
            });
        }
    },[connectionId]);

    if (apiBasePath && user?.id && survey) {
        return (
            <div key='votingPage' ref={transitionNodeRef}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerInnerContainer}>
                        <h1>{survey?.title || 'VOTR'}</h1>
                        <div
                            className={styles.menuButton}
                            onClick={() => {
                                store.dispatch(setIsMenuPanelOpen(true));
                            }}
                        >
                            <FontIcon iconName='BulletedListText' />
                        </div>
                    </div>
                </div>
                <SignalR url={apiBasePath} userId={`${user?.id}`}>
                    <Voting />
                </SignalR>
            </div>
        );
    }
    return <></>;
};