export interface IAnswerOption {
	votesCount: number;
	id: string;
	title: string;
}

export interface IAnsweredOption {
	id: string;
	voteWeight?: number;
}
export interface IRatingAnsweredOption extends IAnsweredOption {
	rating?: number;
	ballotNumber?: number;
}

export enum AnswerChoice {
	yes = 'yes',
	no = 'no',
	abstent = 'abstent',
}

export interface IPersonChoice {
	answerChoice?: AnswerChoice;
}
export interface IPersonAnsweredOption extends IAnsweredOption {
	answerChoice?: AnswerChoice;
	ballotNumber?: number;
}
