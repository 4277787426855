
import React, { useRef } from 'react';
import styles from '../../routes/Hub.module.scss';
import { FontIcon } from 'office-ui-fabric-react';
import { MemberLogo } from '../atoms/MemberLogo';
import { LoginCode } from '../../routes/LoginCode';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store, { AppState } from '../../store/store';
import { setIsMenuPanelOpen } from '../../store/system/system-slice';

export interface Props {
    onValidateUserLogin: (userPin:string) => void;
}


export const LoginUser = (props:Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const design = useSelector((state: AppState) => state.business.design);

    const errors = useSelector((state: AppState) => state.system.errors);

    const transitionNodeRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

    return (
        <div key='loginPage' className={styles.loginPage} ref={transitionNodeRef}>
            <div className={styles.headerContainer}>
                <div className={styles.headerInnerContainer}>
                    <h1>{survey?.title || 'VOTR'}</h1>
                    <div
                        className={styles.menuButton}
                        onClick={() => {
                            store.dispatch(setIsMenuPanelOpen(true));
                        }}
                    >
                        <FontIcon iconName='BulletedListText' />
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={[styles.logoWrapperDiv, design?.logo ? '' : styles.noLogo].join(' ')}>
                    <MemberLogo src={design?.logo} />
                </div>
                {!survey?.useMsal && (
                <LoginCode
                    key='login'
                    label={survey?.useQuickVotes ? t('login.name.label') : t('login.pin.label')}
                    maxLength={survey?.useQuickVotes ? 35 : 6}
                    placeholder={survey?.useQuickVotes ? '' : t('login.pin.placeholder')}
                    onNext={(value) => {
                        props.onValidateUserLogin(value);
                    }}
                    type={survey?.useQuickVotes ? 'name' : 'pin'}
                    validationErrorVisible={errors.some(e => e.area === 'login')}
                    validationErrorMessage={errors.find(e => e.area === 'login')?.message}
                />
                )}
            </div>
        </div>
    );
};
