import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { IQuestionResult, IAnswerOptionResult } from '../../store/business/interfaces';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './ResultGraph.module.scss';
import { formatFloat } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { useTranslation } from 'react-i18next';

interface GraphBarProps {
	allAnswerOptions: IAnswerOptionResult[];
	answerOption: IAnswerOptionResult;
	renderAppendix?: (option: IAnswerOptionResult) => ReactNode;
	useVoteWeightInPercent?: boolean;
	max: number;
}

const GraphBar = (props: GraphBarProps) => {
	const barRef = useRef(null);

	const getOptionResultInPercent = (option: IAnswerOptionResult): number => {
		let optionResultInPercent = 0;
		if (props.useVoteWeightInPercent) {
			optionResultInPercent = option.votesCount;
		} else {
			const totalNumberOfVotes = props.allAnswerOptions.reduce(
				(sum, currentOption) => sum + currentOption.votesCount,
				0
			);
			optionResultInPercent = (option.votesCount * 100) / totalNumberOfVotes;
		}
		return isNaN(optionResultInPercent) ? 0 : optionResultInPercent;
	};

	/**
	 * @param option IAnswerOptionResult
	 * @returns turns %-result number value into string
	 */
	const getAttendeesVoteCountString = (option: IAnswerOptionResult): string => {
		if (!props.useVoteWeightInPercent) return formatFloat(option.votesCount);

		const optionResultInPercent = getOptionResultInPercent(option);
		return `${formatFloat(optionResultInPercent)}`;
	};

	const getAttendeesVoteUnitString = (): string => {
		if (props.useVoteWeightInPercent) return '%';
		return '';
	};

	const getBarStyle = (option: IAnswerOptionResult): React.CSSProperties => {
		return { width: `calc(${(option.votesCount * 100) / props.max}% - ${(option.votesCount * 65) / props.max}px)` };
	};

	const getBarColor = (option: IAnswerOptionResult): React.CSSProperties => {
		return { background: option.votesCount > 0 ? '#0F7681' : '#d1d1d1' };
	};

	const o = props.answerOption;
	return (
		<TransitionGroup enter appear>
			<CSSTransition
				nodeRef={barRef}
				classNames={{
					appear: styles.itemAppear,
					appearActive: styles.itemAppearActive,
					appearDone: styles.itemAppearDone,
				}}
				timeout={100}
			>
				<li ref={barRef} className={styles.item}>
					<h6>{o.title}</h6>
					<div className={styles.flexContainer}>
						<span className={styles.bar} style={{ ...getBarStyle(o), ...getBarColor(o) }}></span>
						<span className={styles.scale}>
							<span className={styles.value}>{getAttendeesVoteCountString(o)}</span>
							{getAttendeesVoteUnitString()}
						</span>
						{props.renderAppendix && props.renderAppendix(o)}
					</div>
				</li>
			</CSSTransition>
		</TransitionGroup>
	);
};

export interface Props {
	question: IQuestionResult;
	renderAppendix?: (option: IAnswerOptionResult) => ReactNode;
}

export const ResultGraph = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const [options, setOptions] = useState<IAnswerOptionResult[]>([]);
	const [notVotedOption, setNotVotedOption] = useState<IAnswerOptionResult>(null);
	const [max, setMax] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		let options = [...props.question.answerOptions];
		if (survey.sortResultsByVotes) {
			options.sort((a, b) => b.votesCount - a.votesCount);
		}
		setOptions(options);
	}, [props.question, survey]);

	useEffect(() => {
		if (survey.useVoteWeightInPercent) {
			setMax(100);
			setNotVotedOption({
				title: t('results.notVoted'),
				votesCount: 100 - options.reduce((sum, currentOption) => sum + currentOption.votesCount, 0),
			} as IAnswerOptionResult);
		} else {
			setMax(options[0]?.votesCount || 1);
		}
	}, [options, survey.useVoteWeightInPercent, t]);

	if (!options || options.length === 0) {
		return <></>;
	}

	return (
		<div className={styles.resultGraph}>
			<ul className={styles.items}>
				{options.map((o, index) => {
					return (
						<GraphBar
							key={index}
							max={max}
							useVoteWeightInPercent={survey.useVoteWeightInPercent}
							allAnswerOptions={options}
							answerOption={o}
							renderAppendix={props.renderAppendix}
						/>
					);
				})}
				{notVotedOption && (
					<GraphBar
						max={max}
						useVoteWeightInPercent={survey.useVoteWeightInPercent}
						allAnswerOptions={options}
						answerOption={notVotedOption}
						renderAppendix={props.renderAppendix}
					/>
				)}
			</ul>
		</div>
	);
};
