import React, { useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AppState, store } from "../../store/store";
import { resetSession, setTenantShortGuid } from "../../store/session/session-slice";
import { SystemService } from "../../services/SystemService";

import  logo  from '../../images/logo.png';
import { LoginCode } from "../../routes/LoginCode";
import  styles from '../../routes/Hub.module.scss';
import { useCallbackRef } from "../../hooks/useCallbackRef";
import { MessageType, showMessage } from "../../shared/notifications";
import { removeError } from "../../store/system/system-slice";


export interface Props {
    onValidateSurveyCode: (surveyCode:string) => void;
}


export const LoginSurvey = (props:Props) => {
    const transitionNodeRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const { t } = useTranslation();
    let { tenantShortGuid: tenantShortGuidParam } = useParams<any>();
	const session = useSelector((state: AppState) => state.session);
    const location = useLocation();

    const errors = useSelector((state: AppState) => state.system.errors);

    const params = new URLSearchParams(location.search);
    const surveyCodeParam = params.get('survey');

    const handleSurveyCodeParam = useCallbackRef(props.onValidateSurveyCode);

    useEffect(() => { 
        // come in with a tenantShortGuidParam
        if(tenantShortGuidParam) {
            // it's different from our remembered param, so reset session
            if(tenantShortGuidParam !== session.tenantShortGuid) {
                store.dispatch((dispatch)=> {
                    dispatch(resetSession());
                    dispatch(setTenantShortGuid(tenantShortGuidParam))
                });
            }
            // move on to root route
            history.push(`/${surveyCodeParam?`?survey=${surveyCodeParam}`:''}`);
        }
        // come in without tenantShortGuidParam (either because it's VOTR Compliance or history.push from above)
        else if(tenantShortGuidParam === undefined) {
            (async () => {
                await SystemService.ensureConfig();
                if(surveyCodeParam) {
                    handleSurveyCodeParam(surveyCodeParam);
                }
                if(errors.some(e => e.area === 'forcedLogout')){
                    showMessage(errors.find(e => e.area === 'forcedLogout')?.message, MessageType.ERROR, true);
                    store.dispatch(removeError('forcedLogout'));
                }
            })();
        }
    }, [tenantShortGuidParam, session.tenantShortGuid, surveyCodeParam, errors, history, handleSurveyCodeParam]);
    
    return (
        <div key='startPage' className={styles.startpage} ref={transitionNodeRef}>
            <div className={styles.headerContainer}>
                <img alt={t('votr')} src={logo} className={styles.votrLogo} />
            </div>
            <div className={styles.contentContainer}>
                <LoginCode
                    key='code'
                    label={t('login.code.label')}
                    placeholder={t('login.code.placeholder')}
                    maxLength={6}
                    onNext={(value) => {
                        props.onValidateSurveyCode(value);
                    }}
                    type='surveyCode'
                    validationErrorVisible={errors.some(e => e.area === 'login')}
                    validationErrorMessage={errors.find(e => e.area === 'login')?.message}
                />
            </div>
            <div className={styles.contentExitContainer}></div>
        </div>
    );
};