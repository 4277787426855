import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMsal } from '@azure/msal-react';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { useSelector } from "react-redux";
import { FontIcon } from "office-ui-fabric-react";

import { MemberLogo } from "../atoms/MemberLogo";
import store, { AppState } from "../../store/store";
import { setIsMenuPanelOpen } from "../../store/system/system-slice";
import { LoadingSpinnerButton } from "../atoms/LoadingSpinnerButton";

import styles from '../../routes/Hub.module.scss';
import styles2 from '../../routes/LoginCode.module.scss';
import { useCallbackRef } from "../../hooks/useCallbackRef";
import { setUserLogin } from "../../store/session/session-slice";


export interface ILoginMsUserProps {
    onValidateUserLogin: (userPin:string) => void;
}


export const LoginMsUser = (props: ILoginMsUserProps) => {
	const transitionNodeRef = useRef<HTMLDivElement>(null);
	const { instance: msalInstance } = useMsal();

    const handleUserLogin = useCallbackRef(props.onValidateUserLogin);

	const survey = useSelector((state: AppState) => state.business.survey);
	const design = useSelector((state: AppState) => state.business.design);
    const session = useSelector((state: AppState) => state.session);
    const isLoading = useSelector((state: AppState) => state.system.isLoading);

    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

    const msalLogin = useCallback(async () => {
        setButtonIsDisabled(true);
 	    var loginRedirectRequest = {
    	    scopes: ['user.read'],
            prompt: 'select_account',
      	} as RedirectRequest;
        await msalInstance.loginRedirect(loginRedirectRequest);
	}, [msalInstance]);

    useEffect(() => {
		msalInstance.initialize().then(() => {
			msalInstance.handleRedirectPromise().then((response: AuthenticationResult) => {
				if(response !== null) {
					if(response.accessToken) {
						store.dispatch(setUserLogin(response.accessToken));
					}
				}
			});	
		});
	}, []);

    useEffect(() => {
        if(session.userLogin) {
            setButtonIsDisabled(true);
            handleUserLogin(session.userLogin);
        } else {
            setButtonIsDisabled(false);
        }
    }, [session.userLogin, handleUserLogin])

    return (
        <div key='loginPage' className={styles.loginPage} ref={transitionNodeRef}>
            <div className={styles.headerContainer}>
                <div className={styles.headerInnerContainer}>
                    <h1>{survey?.title || 'VOTR'}</h1>
                    <div
                        className={styles.menuButton}
                        onClick={() => {
                            store.dispatch(setIsMenuPanelOpen(true));
                        }}
                    >
                        <FontIcon iconName='BulletedListText' />
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={[styles.logoWrapperDiv, design?.logo ? '' : styles.noLogo].join(' ')}>
                    <MemberLogo src={design?.logo} />
                </div>
                <div className={styles2.loginContainer}>
                    <LoadingSpinnerButton
                        loadingClassName={styles2.loadingButton}
                        text="Microsoft Login"
                        onClick={msalLogin}
                        disabled={buttonIsDisabled || isLoading.includes('login')}
                    />						
                </div>
            </div>
        </div>
    );
};
