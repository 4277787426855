import { store } from '../store/store';
import { xhr } from '../xhr';
import { surveyLoaded } from '../store/business/business-slice';
import { QuestionService } from './QuestionService';
import { DesignService } from './DesignService';
import { ISurvey } from '../store/business/interfaces';
import { resetSession, setAppConfig, setSurveyCode, setUser, setUserLogin } from '../store/session/session-slice';
import { addIsLoading, removeIsLoading, setConnectionId } from '../store/system/system-slice';
import { IUser } from '../store/session/interfaces';

export class VotingService {
	public static async ensureSurvey(forceReload = false) {
		const { business } = store.getState();
		if ((business.survey) && !forceReload) {
			return;
		}
		store.dispatch(addIsLoading('survey'));
		try {
			const response = await xhr(`Voting/Survey`, {
				method: 'GET',
				onError: QuestionService.onError,
			});
			if (response.data['title']) {
				store.dispatch(
					surveyLoaded({
						title: response.data['title'],
						useVoteWeightInPercent: response.data['useVoteWeightInPercent'],
						useVoteWeightWithDecimals: response.data['useVoteWeightWithDecimals'],
						useQuickVotes: response.data['useQuickVotes'],
						useMsal: response.data['useMsal'],
						sortResultsByVotes: response.data['sortResultsByVotes'],
						disableResultsInMemberView: response.data['disableResultsInMemberView'],
						showResultsAsPie: response.data['showResultsAsPie'],
					})
				);
			}
			await DesignService.loadDesign('_');
		} finally {
			store.dispatch(removeIsLoading('survey'));
		}
	}

	public static async logout(keepSignalRConn: boolean = false) {
		try {
			await xhr(`Member/Logout${keepSignalRConn?'?keepSignalRConn=true':''}`, {
				method: 'GET',
			});
		} catch (ex) {
			//in case of 401, but can be ignored
		} finally {
			store.dispatch(resetSession())
		}
	}

	public static async login(surveyCode: string, memberLogin?: string): Promise<{survey?: ISurvey, user?: IUser}> {
		const { system } = store.getState();
		if(system.isLoading.includes('login')) return;
		
		let loginResult = {
			survey: undefined,
			user: undefined,
		} as {survey?: ISurvey, user?: IUser};
		try {
			store.dispatch((dispatch) => {
				dispatch(addIsLoading('login'));
				dispatch(addIsLoading('survey'));
			});
			const response = await xhr(`Member/Login`, {
				method: 'POST',
				data: { surveyCode, memberLogin },
			});
			if (response.data.survey) {
				loginResult.survey = {
					title: response.data.survey['title'],
					useVoteWeightInPercent: response.data.survey['useVoteWeightInPercent'],
					useVoteWeightWithDecimals: response.data.survey['useVoteWeightWithDecimals'],
					useQuickVotes: response.data.survey['useQuickVotes'],
					useMsal: response.data.survey['useMsal'],
					sortResultsByVotes: response.data.survey['sortResultsByVotes'],
					disableResultsInMemberView: response.data.survey['disableResultsInMemberView'],
                    showResultsAsPie: response.data.survey['showResultsAsPie'],
				};
				store.dispatch((dispatch) => {
					dispatch(surveyLoaded(loginResult.survey));
					dispatch(setSurveyCode(surveyCode));
				});
			}
			if (response.data.member) {
				loginResult.user = {
					surveyCode: response.data.member['surveyCode'],
					pin: response.data.member['login'],
					id: response.data.member['id'],
				};
				const connectionId = response.data.member['connection'];
				store.dispatch((dispatch) => {
					dispatch(setUser(loginResult.user));
					dispatch(setConnectionId(connectionId));
					dispatch(setUserLogin(''));
				});
			} else {
				store.dispatch(setUser(undefined));
			}
			if(response.data.apiKey) {
				store.dispatch(setAppConfig({functionsKey: response.data.apiKey}));
			}
		} finally {
			store.dispatch((dispatch) => {
				dispatch(removeIsLoading('survey'));
				dispatch(removeIsLoading('login'));
			});
		}
		return loginResult;
	}

	public static async relogin(){
		const { system } = store.getState();
		if(system.isLoading.includes('login')) return;

		try {
			store.dispatch(addIsLoading('login'));
			const response = await xhr(`Member/Relogin`, {
				method: 'POST',
			});
			if (response.data) {
				store.dispatch(setConnectionId(response.data));
			}
		} finally {
			store.dispatch(removeIsLoading('login'));
		}
	}
}
