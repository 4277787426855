import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import styles from './PieChartLegend.module.scss';
import round from 'lodash/round';
import { IAnswerOptionResult } from '../../store/business/interfaces';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { useTranslation } from 'react-i18next';

export const PieChartLegend = (props: any) => {
	const { payload } = props;
	const survey = useSelector((state: AppState) => state.business.survey);
	const { t } = useTranslation();

	const getOptionResultInPercent = (option: IAnswerOptionResult): number => {
		const totalNumberOfVotes = props.payload.reduce(
			(sum: any, currentOption: any) => sum + currentOption.payload.answerOption.votesCount,
			0
		);
		let optionResultInPercent = (option.votesCount * 100) / totalNumberOfVotes;
		optionResultInPercent = isNaN(optionResultInPercent) ? 0 : optionResultInPercent;
		optionResultInPercent = round(optionResultInPercent, 1);
		return optionResultInPercent;
	};

	return (
		<div className={styles.legendContainer}>
			{payload.map((entry: any, index: number) => (
				<div key={`legend-${index}`} className={styles.legendItemContainer}>
					<div className={styles.answerTextContainer}>
						<Icon iconName={'StatusCircleInner'} style={{ color: entry.color }}></Icon>
						<div className={styles.answerText} title={entry.value}>
							{entry.value}
						</div>
					</div>
					<div className={styles.votesCountButtonContainer}>
						<div className={styles.votesCount}>
							<div>{getOptionResultInPercent(entry.payload.answerOption)}%</div>
							{!survey.useVoteWeightInPercent && (<div>&nbsp;&nbsp;({entry.payload.answerOption.votesCount} {t(`form.voices`)})</div>)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
