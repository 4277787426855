import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAppConfig, ISessionState, IUser } from "./interfaces";

const sessionSlice = createSlice({
    name: "session",
    initialState: {
    } as ISessionState,
    reducers: {
        resetSession(state: ISessionState, action: PayloadAction<boolean>) {
            if(action.payload) {
                state.tenantShortGuid = '';
            }
            state.appConfig = undefined;
            state.user = undefined;
            state.surveyCode = '';
            state.userLogin = '';
        },
        setTenantShortGuid(state: ISessionState, action: PayloadAction<string>) {
            state.tenantShortGuid = action.payload;
        },
        setAppConfig(state: ISessionState, action: PayloadAction<IAppConfig>) {
            state.appConfig = {...state.appConfig, ...action.payload};
        },
        setSurveyCode(state: ISessionState, action: PayloadAction<string>) {
            state.surveyCode = action.payload;
        },
        setUserLogin(state: ISessionState, action: PayloadAction<string>) {
            state.userLogin = action.payload;
        },
        setUser: (state: ISessionState, action: PayloadAction<IUser>) => {
            state.user = action.payload;
        },
    },
});

export const sessionReducer = sessionSlice.reducer;
export const { resetSession, setTenantShortGuid, setAppConfig, setSurveyCode, setUserLogin, setUser } = sessionSlice.actions;
