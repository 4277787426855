import React, { useEffect, useCallback } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { VotingService } from '../services/VotingService';
import { AppState } from '../store/store';
import store from '../store/store'
import { Menu } from '../components/Menu';
import { Logo } from '../shared/components/atoms/Logo';
import { showMessage, MessageType } from '../shared/notifications';
import { SidePanel } from '../components/molecules/SidePanel';
import { LoginMsUser } from '../components/molecules/LoginMsUser';
import { LoginUser } from '../components/molecules/LoginUser';
import { LoginSurvey } from '../components/molecules/LoginSurvey';
import { VotingPage } from '../components/molecules/VotingPage';
import { resetSession, setUserLogin } from '../store/session/session-slice';
import styles from './Hub.module.scss';
import { addError, setIsMenuPanelOpen } from '../store/system/system-slice';
import { useTranslation } from 'react-i18next';

export const Hub = () => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();

	const session = useSelector((state: AppState) => state.session);
	const connectionId = useSelector((state: AppState) => state.system.connectionId);

	const survey = useSelector((state: AppState) => state.business.survey);
	const design = useSelector((state: AppState) => state.business.design);
	const isMenuPanelOpen = useSelector((state: AppState) => state.system.isMenuPanelOpen);

	// if user session set, go to voting
	useEffect(() => {
		const params = new URLSearchParams(location.search);
        const surveyCodeParam = params.get('survey');

		const newSurveyCodeSet = !!surveyCodeParam && session.surveyCode !== surveyCodeParam;

		// connection id and user login (survey,pin) as well as survey code are known, go to voting page
		if(connectionId && session.user && (!newSurveyCodeSet) && location.pathname !== '/voting') {
			history.push('/voting');
		// connection id is not known but user login as well as survey code are persisted - relogin silently to get new connection id
		} else if(!connectionId && session.user && !newSurveyCodeSet) {
			VotingService.relogin().then(() => {
				history.push('/voting');
			});
		}
	}, [session.user, connectionId, location, history, session.surveyCode])

	const loginSurveyCodeAndUserLogin = useCallback(async (surveyCodeInput: string, userLoginInput?: string) => {
		VotingService.login(surveyCodeInput, userLoginInput)
			.then(async (loginResult) => {
				if(loginResult.survey) {
					// sent user login...
					if(userLoginInput){
						// ...did it work aka was there a user in the loginResult?
						if(loginResult.user) {
							history.push('/voting');
						} else {
							if(loginResult.survey.useMsal) {
								store.dispatch(setUserLogin(''));
								showMessage('login.msal.errorMsg', MessageType.ERROR, false);
							} else if (loginResult.survey.useQuickVotes) {
								store.dispatch(addError({area: 'login', message: t('login.name.errorMsg')}));
							} else {
								store.dispatch(addError({area: 'login', message: t('login.pin.errorMsg')}));
							}
						}
					// no user login sent, go on to user login route
					} else {
						if(loginResult.survey.useMsal) {
							history.push('/ms-login');
						} else {
							history.push('/login');
						}								
					}
				} else {
					store.dispatch((dispatch)=> {
						dispatch(resetSession());
						dispatch(addError({area: 'login', message: t('login.code.errorMsg')}));
					});
					history.push('/');
				}
			});
	}, [history, t]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		document.title = survey?.title || 'VOTR';
	}, [survey]);

	const logout = useCallback(() => {
		VotingService.logout().then(() => {
			history.push(`/${session.tenantShortGuid ?? ''}`);
		});
	}, [session.tenantShortGuid, history]);
	

	return (
		<div className={styles.pageContainer}>
			<Switch key='switch' location={location}>
				<Route key='userLogin' path='/login' exact={true}>
					<LoginUser 
						onValidateUserLogin={(newUserLogin) => {loginSurveyCodeAndUserLogin(session.surveyCode, newUserLogin);}}
					/>
				</Route>
				<Route key='msUserLogin' path='/ms-login' exact={true}>
					<LoginMsUser
						onValidateUserLogin={(newUserLogin) => {loginSurveyCodeAndUserLogin(session.surveyCode, newUserLogin);}}
					/>
				</Route>
				<Route key='voting' path='/voting' exact={true}>
					<VotingPage />
				</Route>
				<Route key='surveyWithTenant' path='/:tenantShortGuid' exact={false}> 
					<LoginSurvey 
						onValidateSurveyCode={loginSurveyCodeAndUserLogin}
					/>
				</Route>
				<Route key='surveyWithoutTenant' path='/' exact={false}>
					<LoginSurvey 
						onValidateSurveyCode={loginSurveyCodeAndUserLogin}
					/>
				</Route>
			</Switch>
			<SidePanel
				title='VOTR'
				visible={isMenuPanelOpen}
				renderMenu={() => {
					return (
						<>
							<div className={styles.contentContainer}>
								<Menu
									isSignedIn={session.user !== undefined}
									onToggleMenu={() => {
										store.dispatch(setIsMenuPanelOpen(false));
										logout();
									}}
								/>
							</div>
							<div className={styles.menuLogo}>
								<Logo size='big' />
							</div>
						</>
					);
				}}
				onClose={() => store.dispatch(setIsMenuPanelOpen(false))}
				logoSrc={design?.logo}
			/>
			<div className={styles.logo}>
				<Logo />
			</div>
		</div>
	);
};