import React from 'react';
import { PrimaryButton, IButtonProps } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';

export interface Props extends IButtonProps {
	loadingClassName?: string;
	disabled: boolean;
}

export const LoadingSpinnerButton = (props: Props) => {
	const isLoading = useSelector((state: AppState) => state.system.isLoading);

	const { t } = useTranslation();
	return (
		<>{isLoading?.length > 0 ? <div className={props.loadingClassName}>{t('loading.wait')}</div> : <PrimaryButton {...props} />}</>
	);
};
